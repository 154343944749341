// src/components/StoryPreview.tsx

import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import { ParsedStory } from './StoryCreation';
import { useAuth0 } from "@auth0/auth0-react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme, Theme } from '@mui/material/styles';
import './StoryPreview.css'; // Import the CSS file
import axios from 'axios';
import { LinearProgress } from '@mui/material';
import { Buffer } from "buffer";


interface StoryPreviewProps {
  story: ParsedStory;
  onDiscard: () => void;
  displayButtons: boolean;
}

const StoryPreview: React.FC<StoryPreviewProps> = ({ story, onDiscard, displayButtons }) => {
  const { user, isAuthenticated } = useAuth0();
  const [isSavingStory, setIsSavingStory] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const [audioURL, setAudioURL] = useState<string>("");
  const [isGeneratingAudio, setIsGenerationAudio] = useState(false);
  const theme: Theme = useTheme();

  const convertToSpeech = async () => {
    setIsGenerationAudio(true);
    let part_strings: string[] = [];
    story.parts.map((part: typeof story.parts[0], idx: number) => (
      part_strings.push(
        `<speak><s>${part.part_title}</s> ${part.content}<s></s></speak>`
      )
    ));

    // iterate through the parts and generate the audio for each part
    // append to a global buffer and set audio url
    let audioBuffer = Buffer.from("", 'utf8');
    for (let i = 0; i < part_strings.length; i++) {
      const speechDetails = await axios.post("/api/generateaudio", {
        data: part_strings[i]
      });
      audioBuffer = Buffer.concat([audioBuffer, Buffer.from(speechDetails.data, 'utf8')]);
    }
    const blob = new Blob([audioBuffer], { type: 'audio/mp3' });
    const url = URL.createObjectURL(blob)
    setIsGenerationAudio(false);
    setAudioURL(url);
};

  const handleDiscardStory = () => {
  // Here you can clear the story or navigate away or any other logic you want
  // If you're using a state to hold the story data in a parent component, you can pass a prop to reset it
  // For example: props.onDiscard();
    onDiscard();
  };

  const handleSaveStory = async () => {
    setIsSavingStory(true);
    try {
      if (isAuthenticated) {
        const res = await fetch("/api/savestory", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_email: user?.email,
            story: {
              content: story
            }
          }),
        });
        if (res.status === 200) {
          const data = await res.json();
          setIsSaved(true);
        } else {
          console.log("There was an error saving your story.");
          setErrorSaving(true);
        }
      } else {
        alert("You must be logged in to save a story.");
      }
    } catch (error) {
      console.error(error);
      setIsSaved(false);
    }
    setIsSavingStory(false);
  };
  return (
    <div style={{ backgroundColor: theme.palette.background.default}}>
      <div className="story-preview">
        <h2>{story.title}</h2>
        {
          isGeneratingAudio ? (
            <LinearProgress />
          ) : audioURL ? (
            <div>
              {audioURL && (
                <audio autoPlay controls>
                  <source src={audioURL} type="audio/mpeg" />
                </audio>
              )}
            </div>
          ) : (
              <Button
              variant="outlined"
              color="primary"
              endIcon={<VoiceChatIcon />}
              onClick={convertToSpeech}
            >
              Listen
            </Button>
          )
        }
        {story.parts.map((part: typeof story.parts[0], idx: number) => (
          <div key={idx} className="story-part">
            <h6>{part.part_title}</h6>
            {/* Conditionally render the illustration if it's not an empty string */}
            {part.illustration && (
              <div className="illustration-container">
                <img src={part.illustration} alt={`Illustration for part ${idx + 1}`} />
              </div>
            )}
            <p>{part.content}</p>
          </div>
        ))}
        {
          displayButtons ? (
            <div className='button-container'>
              <Button 
                  variant="contained" 
                  color={isSaved ? "success" : errorSaving ? "error" : "primary"} 
                  onClick={handleSaveStory}
                  startIcon={isSaved ? <CheckCircleIcon /> : errorSaving ? <ErrorIcon /> : null}
                  className="save-story-button"
              >
                  {isSavingStory ? "Saving..." : isSaved ? "Saved" : errorSaving ? "Error" : "Save"}
              </Button>

              <Button 
                  variant="outlined" 
                  color="secondary"
                  onClick={handleDiscardStory}
                  className="discard-story-button"
              >
                  Discard
              </Button>
            </div>
          ) : (
            ""
          )
        }
      </div>
    </div>
  );
}

export default StoryPreview;
