import React from 'react';
import { Button, Container, Grid, Typography, Box } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

const backgroundImage = '/images/background.webp';
const textStyle = {
    color: 'rgba(255, 255, 255, 0.87)',  // A lighter white with some transparency
};

const LandingPage: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  return (
    <div style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100vw',
      height: '100vh',
      position: 'absolute',  // This makes sure the div covers the whole viewport
      top: 0,
      left: 0,
      zIndex: 0   // Ensures the background stays behind the content
    }}>
      <Container style={{position: 'relative', zIndex: 1}}>
        {/* Hero Section */}
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="60vh">
          <Typography variant="h3" style={textStyle}>Bringing Stories to Life!</Typography>
          {
          isAuthenticated ? 
            // <Button color="inherit" onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button> :
            "" : <Button variant="contained" color="inherit" onClick={() => loginWithRedirect()}>Login</Button>
          }
        </Box>

        {/* Features Section */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" style={textStyle}>Custom Stories</Typography>
            <Typography style={textStyle}>Generate stories tailored to your preferences</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" style={textStyle}>Illustrations</Typography>
            <Typography style={textStyle}>Get unique illustrations for each story</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" style={textStyle}>Save & Share</Typography>
            <Typography style={textStyle}>Save your favorite stories and share with friends</Typography>
          </Grid>
        </Grid>

        {/* Testimonials */}
        {/* ... You can add testimonials in a similar grid format as the features section ... */}
      </Container>
    </div>
  );
}

export default LandingPage;
