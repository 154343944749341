import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from "@auth0/auth0-react";

const theme = createTheme({
  palette: {
    background: {
      default: '#EDE7F6' // soft lavender for the main background
    },
    primary: {
      main: '#0B3D91', // rich purple for primary actions
    },
    secondary: {
      main: '#3C7CD9', // gold for secondary actions
    },
  },
  typography: {
    h1: {
      fontFamily: 'Nunito',
    },
    h2: {
      fontFamily: 'Nunito',
    },
    h3: {
      fontFamily: 'Nunito',
    },
    h4: {
      fontFamily: 'Nunito',
    },
    h5: {
      fontFamily: 'Nunito',
    },
    h6: {
      fontFamily: 'Nunito',
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        authorizationParams={{
          redirect_uri: window.location.origin
        }}
      >
        <App />
      </Auth0Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
