import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import styles from './MyStories.module.css';
import { CircularProgress, Box } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
import StoryPreview from './StoryPreview';

interface Story {
  created_at: string;
  content: {
    title: string;
    parts: [
      {
        part_title: string;
        content: string;
        illustration: string;
      }
    ]
  }
  // add any other properties of the story here
}

const MyStories = () => {
  const theme: Theme = useTheme();
  const [stories, setStories] = useState<Story[]>([]);
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const { user, isAuthenticated, logout } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch the stories when the component is mounted
    setIsLoading(true);
    const fetchStories = async () => {
      if (isAuthenticated && user?.email) {
        try {
          const response = await axios.post("/api/getuserstories",
            {
              user_email: user.email
            }
          );
          setStories(response.data.stories);
        } catch (error) {
          console.error('Error fetching the stories:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        alert("You must be logged in to get stories.");
      }

    };

    fetchStories();
  }, [isAuthenticated, user?.email]);

  if (isLoading) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <CircularProgress />
        </Box>
    );
  }

  return (
    <div style={{ backgroundColor: theme.palette.background.default }}>
      <div className={styles.contentArea}>
        <div style={{ flex: 1, borderRight: '1px solid gray', padding: '10px' }}>
            <h3 className={styles.sidebarTitle}>My Stories</h3>
            {stories.length > 0 ? (
                stories.map(story => (
                    <div 
                        key={story.content.title} 
                        onClick={() => setSelectedStory(story)}
                        className={`${styles.storyItem} ${selectedStory?.content.title === story.content.title ? styles.selected : ''}`}
                    >
                        {story.content.title}
                    </div>
                ))
            ) : (
                <p>No stories available.</p>
            )}
        </div>

          <div className={styles.storyDisplay}>
              {selectedStory && (
                  <>
                    <StoryPreview story={selectedStory.content} onDiscard={() => null} displayButtons={false} />
                  </>
              )}
          </div>
      </div>
    </div>
  );
};

export default MyStories;
