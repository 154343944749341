import {
    AppBar, Toolbar, Typography, Button, Drawer, List, ListItem, IconButton
} from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { useTheme, Theme } from '@mui/material/styles';

const headerStyles = {
    appBar: {
        zIndex: 1200,  // MUI's default AppBar z-index is 1100. Increase it if needed.
        position: 'relative',
    },
    title: {
        flexGrow: 1
    },
    navLinks: {
        marginLeft: '10px',
        fontFamily: 'Nunito'
    },
    titleLink: {
        textDecoration: 'none',
        color: 'inherit',
        flexGrow: 1
    }
};

const Header = () => {
    const theme: Theme = useTheme();
    const { isAuthenticated, logout } = useAuth0();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isNarrowScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const drawerButtons = (
        <List>
            {isAuthenticated ? (
                <>
                    <ListItem button component={Link} to="/" onClick={() => setDrawerOpen(false)}>Home</ListItem>
                    <ListItem button component={Link} to="/mystories" onClick={() => setDrawerOpen(false)}>My Stories</ListItem>
                    <ListItem button onClick={() => { logout({ logoutParams: { returnTo: window.location.origin } }); setDrawerOpen(false); }}>Logout</ListItem>
                </>
            ) : (
                <>
                    <ListItem button onClick={() => setDrawerOpen(false)}>Home</ListItem>
                    <ListItem button onClick={() => setDrawerOpen(false)}>About</ListItem>
                    <ListItem button onClick={() => setDrawerOpen(false)}>Contact</ListItem>
                </>
            )}
        </List>
    );

    return (
        <AppBar position="static" style={{position: 'relative', zIndex: 1200}}>
            <Toolbar>
                <Link to="/" style={headerStyles.titleLink}>
                    <Typography variant="h6">
                        Goodnight Story AI
                    </Typography>
                </Link>
                {isNarrowScreen ? (
                    <>
                        <IconButton edge="end" color="inherit" onClick={() => setDrawerOpen(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                            {drawerButtons}
                        </Drawer>
                    </>
                ) : (
                    <>
                        {isAuthenticated ? (
                            <>
                                <Button color="inherit" style={headerStyles.navLinks} component={Link} to="/">Home</Button>
                                <Button color="inherit" style={headerStyles.navLinks} component={Link} to="/mystories">My Stories</Button>
                                <Button color="inherit" style={headerStyles.navLinks} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</Button>
                            </>
                        ) : (
                            <>
                                <Button color="inherit" style={headerStyles.navLinks}>Home</Button>
                                <Button color="inherit" style={headerStyles.navLinks}>About</Button>
                                <Button color="inherit" style={headerStyles.navLinks}>Contact</Button>
                            </>
                        )}
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
