// src/App.tsx

import React, { FC, useState } from 'react';
import { Analytics } from '@vercel/analytics/react';
import StoryCreation from './components/StoryCreation';
import StoryPreview from './components/StoryPreview';
import Header from './components/Header';
import { Footer } from './components/Footer';
import LandingPage from './components/LandingPage';
import MyStories from './components/MyStories';
import { Lan } from '@mui/icons-material';
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';


const App: FC = () => {
    const [parsedStory, setParsedStory] = useState<any | null>(null);
    const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();
    const theme: Theme = useTheme();

    const handleStoryDiscard = () => {
      setParsedStory(null);
    }; 


    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: theme.palette.background.default}}>
            <Header />
            <div style={{ flexGrow: 1, zIndex: 1100}}>
              <Routes>
                <Route path="/mystories" element={<MyStories />} />
                <Route path="/" element={
                  isAuthenticated ?
                  (
                      <>
                          <StoryCreation onStoryGenerated={setParsedStory} />
                          {parsedStory && <StoryPreview story={parsedStory} onDiscard={handleStoryDiscard} displayButtons={true} />}
                      </>
                  ) :
                  (
                      <>
                          <LandingPage />
                      </>
                  )
                } />
              </Routes>
            </div>
            <Footer />
            <Analytics />
        </div>
      </Router>
    );
}

export default App;
