import { Box } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';

export const Footer = () => {
  const theme: Theme = useTheme();
  return (
    <div style={{ backgroundColor: theme.palette.primary.main}}>
      <Box padding="20px 0" marginTop="0px" textAlign="center" zIndex={1} color={"white"}>
          © 2023 Goodnight AI.
      </Box>
    </div>
  );
};
